import { GlobalDataContext } from 'providers/globalDataProvider'
import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
// css
import './mainSlider.scss'

const MainSlider = ({ dataSlider, isLoading }) => {
  const { TEMPLATE_CONFIG } = useContext(GlobalDataContext)
  const companyInfo = TEMPLATE_CONFIG?.templateData?.company_info
  const [activeSlide, setActiveSlide] = useState(0)
  const maxSlide = dataSlider.length

  const changeActiveSlide = (index) => {
    if (!isNaN(index) && index <= maxSlide - 1 && index >= 0) {
      setActiveSlide(index)
    } else {
      let slide = index === 'prev' ? activeSlide - 1 : activeSlide + 1
      if (slide < 0) {
        slide = maxSlide - 1
      }
      if (slide > maxSlide - 1) {
        slide = 0
      }
      setActiveSlide(slide)
    }
  }

  return (
    <div className={`main-slider ${isLoading ? 'main-slider-loading' : ''} df-padding-2`}>
      {dataSlider.map((slide, index) => (
        <div
          key={index}
          className="lc-container content-slider"
          style={activeSlide !== index ? { display: 'none' } : {}}
        >
          {/* Left */}
          <div className="left">
            {/* Data */}
            <div className="data">
              <TransitionGroup>
                <CSSTransition timeout={1000} classNames="text" key={activeSlide}>
                  <div className="text">
                    <h1 className="small-title">{slide?.title?.top}</h1>
                    <h1 className="big-title">
                      <span className="main-color-2">{slide?.title?.bottom}</span>
                    </h1>
                    <p className="description">{slide.description}</p>
                  </div>
                </CSSTransition>
              </TransitionGroup>
              <TransitionGroup>
                <CSSTransition timeout={1000} classNames="buttons" key={activeSlide}>
                  <div className="buttons">
                    <a
                      className="default-btn"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://wa.me/57${companyInfo?.whatsapp}?text=${
                        slide?.button_config?.url !== ''
                          ? slide?.button_config?.url
                          : slide?.button_config?.text_copy
                      }`}
                    >
                      {slide?.button_config?.text ?? 'Ordenar Ahora'}
                    </a>
                  </div>
                </CSSTransition>
              </TransitionGroup>
            </div>
            {/* Social Networks */}
            <div className="social-networks">
              {companyInfo?.instagram ? (
                <a
                  href={companyInfo.instagram}
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Instagram"
                >
                  <img
                    src={require('assets/img/social/instagram.svg').default}
                    className="social-icon"
                    alt="Instagram"
                  />
                </a>
              ) : null}

              {companyInfo?.facebook ? (
                <a
                  href={companyInfo.facebook}
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Facebook"
                >
                  <img
                    src={require('assets/img/social/facebook.svg').default}
                    className="social-icon"
                    alt="Facebook"
                  />
                </a>
              ) : null}

              {companyInfo?.whatsapp ? (
                <a
                  href={`https://wa.me/57${companyInfo.whatsapp}?text=Hola, estoy interesado en...`}
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Whatsapp"
                >
                  <img
                    src={require('assets/img/social/whatsapp.svg').default}
                    className="social-icon"
                    alt="Maps"
                  />
                </a>
              ) : null}
            </div>
          </div>
          {/* Right */}
          <div className="right">
            <div className="slider">
              <TransitionGroup>
                <CSSTransition timeout={1000} classNames="image" key={activeSlide}>
                  {/* <div className="container-img-slide" style={{ backgroundImage: `url(${require(`assets/img/${slide.image}`).default})` }}  >
                                            <img src={require(`assets/img/${slide.image}`).default} className="img-slide" alt="Imagen"/>
                                        </div> */}
                  <div
                    className="container-img-slide"
                    style={{
                      // backgroundImage: `url(${require(`assets/img/${slide.image}`).default})`
                      backgroundImage: `url(${slide?.featured_image})`
                    }}
                  >
                    {/* <img src={require(`assets/img/${slide.image}`).default} className="img-slide" alt="Imagen"/> */}
                  </div>
                </CSSTransition>
              </TransitionGroup>
              <div className="controls">
                <div className="lc-placeholder">{/* // */}</div>
                <div className="arrows">
                  <div onClick={() => changeActiveSlide('prev')} className="arrow" title="Anterior">
                    <span className="material-icons arrow-left">arrow_back_ios</span>
                  </div>
                  <div
                    onClick={() => changeActiveSlide('next')}
                    className="arrow arrow-active"
                    title="Siguiente"
                  >
                    <span className="material-icons">arrow_forward_ios</span>
                  </div>
                </div>
              </div>
              <div className="slider-indicators">
                {dataSlider.map((slide, index) => (
                  <span
                    className={`indicator ${activeSlide === index ? 'indicator-active' : ''}`}
                    key={index}
                    onClick={() => changeActiveSlide(index)}
                  ></span>
                ))}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

MainSlider.propTypes = {
  dataSlider: PropTypes.array
}

// C1 => Color 1, C2 => Color 2
MainSlider.defaultProps = {
  // dataSlider: [
  //     {
  //         smallTitle: 'Try Our New',
  //         bigTitleC1: 'Cheezy',
  //         bigTitleC2: 'Burger',
  //         description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt ab sed unde?',
  //         image: 'main-slider/default-image.png',
  //         order: '¡Hola!, Estoy interesado en un platillo de Lechoncitos.'
  //     },
  //     {
  //         smallTitle: 'Try Our New 2',
  //         bigTitleC1: 'Meat',
  //         bigTitleC2: 'Creep',
  //         description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt ab sed unde?',
  //         image: 'main-slider/default-image.png',
  //         order: '¡Hola!, Estoy interesado en un platillo de Lechoncitos 2'
  //     }
  // ]
  dataSlider: [
    {
      title: {
        top: '',
        bottom: ''
      },
      featured_image: '',
      description: '',
      button_config: {
        text: '',
        text_copy: '',
        url: ''
      }
    }
  ]
}

export default MainSlider
